<template>
	<div class="level-description">
		<w-navTab titleText="义警服务证"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<div class="header">
			<div class="header-content" v-if="isTopShow == false">
				<div class="header-content-title">
					<div class="header-title-icon">
						<img src="@/assets/img/u_icon104.png" alt="">
					</div>
					<div class="header-title-right">
						<div class="title-right-title">义警服务证</div>
						<div class="title-right-text">{{userInfo.district_name}}平安义警协会</div>
					</div>
				</div>
				<div class="header-content-middle">
					<div class="header-middle-item">
						<div class="middle-item-lable">姓名/Name</div>
						<div class="middle-item-text">{{userInfo.name}}</div>
					</div>
					<div class="header-middle-item">
						<div class="middle-item-lable">性别/Sex</div>
						<div class="middle-item-text">{{userInfo.sex}}</div>
					</div>
					<div class="header-middle-item">
						<div class="middle-item-lable">地区/Add</div>
						<div class="middle-item-text">{{userInfo.district_name}}</div>
					</div>
				</div>
				<div class="header-content-middle">
					<div class="header-middle-item" v-if="userInfo.member_level != '' && userInfo.role_type == 3">
						<div class="middle-item-lable">职衔/Rank</div>
						<div class="middle-item-text">{{userInfo.member_level}}</div>
					</div>
					<div class="header-middle-item">
						<div class="middle-item-lable">义警编号/No.</div>
						<div class="middle-item-text lable-active">{{userInfo.police_number}}</div>
					</div>
				</div>
			</div>
			<div class="header-box" v-else>
				<div class="header-box-icon">
					<img src="@/assets/img/u_icon104.png" alt="">
				</div>
				<div class="header-box-title">
					奉献 友爱 互助 进步
				</div>
				<div class="header-box-time">
					有效期：{{validTime.start_ime}} 至 {{validTime.end_time}}
				</div>
			</div>
			<div class="headert-content-right">
				<div class="content-right-refresh" @click="isTopShow = !isTopShow">
					<img src="@/assets/img/u_icon109.png" alt="">
				</div>
				<div class="content-right-avatar" >
					<div class="right-avatar-img" v-if="isTopShow == false">
						<img v-if="userInfo.police_photo == ''" :src="userInfo.avatar" alt="" />
						<img v-else :src="userInfo.police_photo" alt="" />
					</div>
					<div class="right-avatar-text" v-if="isTopShow == false">{{userInfo.duty}}</div>
				</div>
				<div class="content-right-share">
					<div class="right-share-icon">
						<img src="@/assets/img/u_icon101.png" alt="">
					</div>
					<div class="right-share-text" @click="$router.push({ name: 'LevelDescriptionShare' })">分享
					</div>
				</div>
			</div>
			<div class="header-nav">
				<div :class="['header-nav-item',{'item-active': navIndex == index}]" v-for="(item,index) in navList"
					:key="index" @click="onClickNav(index)">
					{{item.title}}
				</div>
			</div>
		</div>
		
		<div class="body">
			<div class="body-info" v-if="navIndex == 0">
				<div class="body-info-item">
					<div class="info-item-lable">用户姓名</div>
					<div class="info-item-text">{{userInfo.name}}</div>
				</div>
				<div class="body-info-item">
					<div class="info-item-lable">用户等级</div>
					<div class="info-item-text">{{userInfo.level_text}}</div>
				</div>
				<div class="body-info-item" v-if="userInfo.member_level != '' && userInfo.role_type == 3">
					<div class="info-item-lable">职衔</div>
					<div class="info-item-text">{{userInfo.member_level}}</div>
				</div>
				<div class="body-info-item">
					<div class="info-item-lable">所属队伍</div>
					<div class="info-item-text">{{userInfo.department_name}}</div>
				</div>
				<div class="body-info-item">
					<div class="info-item-lable">身份证号</div>
					<div class="info-item-text">{{userInfo.id_card}}</div>
				</div>
				<div class="body-info-item">
					<div class="info-item-lable">缴纳会费</div>
					<div class="info-item-text">{{userInfo.is_payment == 1?'否':'是'}}</div>
				</div>
			</div>
			<div class="body-container" v-if="navIndex == 1">
				<div class="body-container-text">数据截至{{timeText}}</div>
				<div class="body-content">
					<div class="body-content-title">
						<div class="content-title-left">服务统计</div>
					</div>
					<div class="body-content-service">
						<div class="content-service-item">
							<div class="service-item-title">活动打卡</div>
							<div class="service-item-number color-red">
								{{service.register_count}}<span>次</span>
							</div>
						</div>
						<div class="content-service-item">
							<div class="service-item-title">服务时长</div>
							<div class="service-item-number color-green">
								{{getMath(service.service_time)}}<span>时</span>
							</div>
						</div>
						<div class="content-service-item">
							<div class="service-item-title">获得爱心值</div>
							<div class="service-item-number color-blue">
								{{service.love_value}}<span>个</span>
							</div>
						</div>
					</div>
				</div>
				<div class="body-content">
					<div class="body-content-title">
						<div class="content-title-left">活动类型</div>
					</div>
					<div class="body-content-chart" :id="id"></div>
					<div class="content-chart-total">
						<div class="chart-total-number">{{total}}</div>
						<div class="chart-total-text">总数</div>
					</div>
				</div>
				<div class="body-content">
					<div class="body-content-title">
						<div class="content-title-left">年度统计</div>
						<div class="content-title-right" @click="isTimeShow = true">
							{{timeYear}}年<van-icon style="margin-left: 2px;" name="arrow-down" />
						</div>
					</div>
					<div class="body-content-annual">
						<div class="content-annual">
							<div class="content-annual-item">
								<div class="annual-item-header">
									<div class="annual-item-icon">
										<img src="@/assets/img/u_icon105.png" alt="">
									</div>
									<div class="annual-item-title">
										活动打卡<span>/次</span>
									</div>
								</div>
								<div class="annual-item-number">{{annual.register_count}}</div>
							</div>
							<div class="content-annual-item">
								<div class="annual-item-header">
									<div class="annual-item-icon">
										<img src="@/assets/img/u_icon106.png" alt="">
									</div>
									<div class="annual-item-title">
										服务时长<span>/小时</span>
									</div>
								</div>
								<div class="annual-item-number">{{getMath(annual.service_time)}}</div>
							</div>
						</div>
						<div class="content-annual">
							<div class="content-annual-item">
								<div class="annual-item-header">
									<div class="annual-item-icon">
										<img src="@/assets/img/u_icon107.png" alt="">
									</div>
									<div class="annual-item-title">
										爱心值<span>/个</span>
									</div>
								</div>
								<div class="annual-item-number">{{annual.love_value}}</div>
							</div>
							<div class="content-annual-item">
								<div class="annual-item-header">
									<div class="annual-item-icon">
										<img src="@/assets/img/u_icon108.png" alt="">
									</div>
									<div class="annual-item-title">
										签到天数<span>/天</span>
									</div>
								</div>
								<div class="annual-item-number">{{annual.signup_count}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<van-popup v-model="isTimeShow" position="bottom">
			<van-picker title="标题" show-toolbar :default-index="statusIndex" :columns="columns" @confirm="confirmTime"
				@cancel="isTimeShow = false" />
		</van-popup>
	</div>
</template>

<script>
	import User from '@/api/user';
	import * as echarts from 'echarts'
	import LoadingPage from '@/components/LoadingPage';
	import Moment from '@/vendor/moment';
	import VantVendor from '@/vendor/vant';

	export default {
		inject: ['className', 'districtType', 'themeColor'],
		name: 'LevelDescription',
		data() {
			return {
				isTopShow: false,
				loadingPage: true,
				userInfo: "",
				validTime: "",
				navIndex: 0,
				navList: [{
					id: 1,
					title: '基本信息'
				}, {
					id: 2,
					title: '数据统计'
				}],

				id: 'main',
				timeText: '',
				timeYear: '',
				timeMonth: '',

				service: '',
				annual: '',
				typeList: [],
				total: '',

				statusIndex: 0,
				isTimeShow: false,
				columns: ['2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030', '2031', '2032', '2033',
					'2034', '2035', '2036', '2037', '2038', '2039', '2040', '2041', '2042', '2043', '2044', '2045',
					'2046', '2047', '2048', '2049', '2050'
				],
				chart: null
			}
		},
		created() {
			this.timeText = Moment(new Date()).format('YYYY-MM-DD')
			this.timeYear = Moment().format('YYYY')
			this.getUserBasicInfo()
			this.getService()
			this.getAnnual()
		},
		mounted() {
			// this.myChart();
		},
		methods: {
			getMath(min) {
				let time = min / 60
				return time.toFixed(2);
			},
			// 用户详情
			getUserBasicInfo() {
				User.getUserBasicInfo()
					.then(result => {
						this.userInfo = result.data
						this.validTime = result.data.valid_time
						this.loadingPage = false
					})
					.catch(error => {
						VantVendor.Toast.fail(error.msg)
					})
			},
			// 服务统计
			getService() {
				User.service()
					.then(result => {
						this.service = result.data
					})
					.catch(error => {
						VantVendor.Toast.fail(error.msg);
					});
			},
			// 活动类型统计
			getType() {
				User.type()
					.then(result => {
						let list = []
						result.data.list.forEach(item => {

							let params = {
								value: item.total,
								name: item.group_name + '(' + parseInt(item.total) + ')'
							}
							list.push(params)
						})
						this.typeList = list
						this.total = result.data.total
						this.myChart();
					})
					.catch(error => {
						VantVendor.Toast.fail(error.msg);
					});
			},
			// 年度统计
			getAnnual() {
				let params = {
					year: this.timeYear
				}
				User.annual(params)
					.then(result => {
						this.annual = result.data
					})
					.catch(error => {
						VantVendor.Toast.fail(error.msg);
					});
			},
			onClickNav(index) {
				this.navIndex = index
				if(index == 1){
					this.getType()
				}else if(index != 1 && this.chart != null){
					this.chart.dispose()
				}
			},
			myChart() {
				// 获取节点
				this.chart = echarts.init(document.getElementById(this.id));
				// 配置项
				var option = {
					tooltip: {
						trigger: 'item'
					},
					legend: {
						orient: 'vertical',
						top: 20,
						right: 10,
						itemWidth: 12,
						itemHeight: 12,
						textStyle: {
							color: '#3A3B3E',
							fontSize: 13,
						},
					},
					color: ['#FD5461', '#7964FF', '#3AA0FF', '#FE8C46', '#2EDFFF', '#4DDA77', '#FFCD3A', '#435188'],
					series: [{
						left: '-65%',
						type: 'pie',
						radius: ['45%', '65%'],
						avoidLabelOverlap: false,
						label: {
							show: false,
							position: 'center'
						},
						labelLine: {
							show: false
						},
						data: this.typeList
					}]
				};

				this.chart.setOption(option);
			},
			confirmTime(e, index) {
				this.isTimeShow = false
				this.statusIndex = index
				this.timeYear = Moment(new Date(e)).format('YYYY');
				this.getAnnual()
			}
		},
		components: {
			LoadingPage
		}

	};
</script>

<style lang="less" scoped>
	.level-description {
		.header {
			padding: 16px 9px 8px;
			box-sizing: border-box;
			background: #FFF;
			position: relative;
			
			.header-content {
				padding: 29px 23px;
				width: 357px;
				height: 211px;
				background-image: url('../../assets/img/u_icon103.png');
				background-size: cover;
				box-sizing: border-box;

				.header-content-title {
					display: flex;
					align-items: center;

					.header-title-icon {
						width: 42px;
						height: 42px;
						min-width: 42px;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}

					.header-title-right {
						padding-left: 8px;

						.title-right-title {
							color: #020917;
							font-size: 17px;
							font-weight: bold;
							line-height: 20px;
						}

						.title-right-text {
							color: #333;
							margin-top: 4px;
							font-size: 12px;
						}
					}
				}

				.header-middle-item {
					margin-right: 24px;

					.middle-item-lable {
						color: #333;
						font-size: 12px;
						line-height: 18px;
					}

					.middle-item-text {
						color: #020917;
						font-size: 15px;
						margin-top: 4px;
						font-weight: bold;
						line-height: 23px;

						&.lable-active {
							font-size: 18px;
						}
					}
				}

				.header-content-middle {
					margin: 16px 0 12px;
					display: flex;
					align-items: center;
				}

			}
			
			.header-box{
				padding: 54px 23px 0;
				width: 357px;
				height: 211px;
				background-image: url('../../assets/img/u_icon115.png');
				background-size: cover;
				box-sizing: border-box;
				text-align: center;
				
				.header-box-icon{
					width: 42px;
					height: 42px;
					margin: 0 auto;
					
					img{
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
				.header-box-title{
					color: #020917;
					font-size: 20px;
					line-height: 24px;
					margin-top: 16px;
				}
				.header-box-time{
					margin-top: 8px;
					color: #333;
					font-size: 13px;
					line-height: 20px;
				}
			}
			
			.headert-content-right {
				position: absolute;
				top: 31px;
				right: 16px;
			
				.content-right-refresh {
					padding: 1px 11px;
					margin-left: 41px;
					width: 42px;
					height: 22px;
					border-radius: 0px 16px;
					background: rgba(0, 0, 0, 0.20);
					box-sizing: border-box;
					position: absolute;
					top: 0;
					right: 0;
			
					img {
						width: 20px;
						height: 20px;
						object-fit: cover;
					}
				}
			
				.content-right-avatar {
					height: 110px;
					margin-right: 20px;
					margin: 32px 20px 0 0;
					padding-top: 94px;
					box-sizing: border-box;
					position: relative;
					
					.right-avatar-img {
						width: 63px;
						height: 86px;
						position: absolute;
						right: 0;
						top: 0;
						
						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
			
					.right-avatar-text {
						color: #FFF;
						font-size: 14px;
						line-height: 16px;
						text-align: center;
					}
				}
			
				.content-right-share {
					position: absolute;
					right: 25px;
					padding: 2px 0;
					width: 54px;
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 20px;
					background: rgba(0, 0, 0, 0.20);
					box-sizing: border-box;
					margin: 24px 0 0 19px;
			
					.right-share-icon {
						width: 12px;
						height: 12px;
						margin-right: 2px;
			
						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
			
					.right-share-text {
						color: #FFF;
						font-size: 12px;
					}
				}
			}
			
			.header-nav {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 20px;

				.header-nav-item {
					text-align: center;
					color: #9F9EA7;
					font-size: 16px;
					line-height: 20px;
					margin: 0 16px;

					&::after {
						content: '';
						display: block;
						width: 20px;
						height: 2px;
						border-radius: 23px;
						background: #FFF;
						margin: 6px auto 0;
					}

					&.item-active {
						color: #3A3B3E;
						font-size: 18px;
						font-weight: bold;

						&::after {
							content: '';
							display: block;
							width: 20px;
							height: 2px;
							border-radius: 23px;
							background: #37F;
							margin: 6px auto 0;
						}
					}
				}
			}
		}

		.body {
			.body-info {
				margin: 20px 16px;
				border-radius: 8px;
				background: #FFF;
				padding: 4px 12px 8px;
				box-sizing: border-box;

				.body-info-item {
					padding: 12px 0;
					font-size: 15px;
					line-height: 24px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					border-bottom: 1px solid rgba(34, 34, 34, .1);
					box-sizing: border-box;

					&.body-info-item:last-child {
						border: 0;
					}

					.info-item-lable {
						color: #A1A5B1;
					}

					.info-item-text {
						color: #3A3B3E;

					}
				}
			}

			.body-container {
				padding: 16px;
				box-sizing: border-box;

				.body-container-text {
					color: #999;
					font-size: 14px;
					margin-bottom: 12px;
					line-height: 21px;
				}

				.body-content {
					position: relative;

					.body-content-title {
						display: flex;
						justify-content: space-between;
						align-items: center;

						.content-title-left {
							color: #3A3B3E;
							font-size: 18px;
							font-weight: bold;
							line-height: 20px;

							&::after {
								content: '';
								display: block;
								width: 112px;
								height: 6px;
								background: linear-gradient(90deg, #37F 0.02%, rgba(51, 119, 255, 0.00) 100.86%);
								margin-top: -4px;
								margin-left: -2px;
							}
						}

						.content-title-right {
							color: #A1A5B1;
							font-size: 14px;
							line-height: 20px;
						}
					}

					.body-content-service {
						display: flex;
						justify-content: space-between;
						align-items: center;
						margin: 20px 0 28px;

						.content-service-item {
							padding: 12px 8px;
							width: 108px;
							border-radius: 8px;
							background: #FFF;
							box-sizing: border-box;

							.service-item-title {
								color: #A1A5B1;
								font-size: 14px;
								line-height: 21px;
							}

							.service-item-number {
								font-size: 20px;
								margin-top: 16px;

								span {
									font-size: 13px;
									line-height: 22px;
								}

								&.color-red {
									color: #FF7A7B;
								}

								&.color-green {
									color: #3AE396;
								}

								&.color-blue {
									color: #57C2FF;
								}
							}
						}
					}

					.body-content-chart {
						margin: 20px 0;
						width: 100%;
						border-radius: 12px;
						background: #FFF;
						height: 150px;
					}

					.content-chart-total {
						width: 70px;
						position: absolute;
						left: 25px;
						bottom: 50px;
						z-index: 10000 !important;

						.chart-total-number {
							color: #FD5461;
							text-align: center;
							font-size: 18px;
						}

						.chart-total-text {
							color: #3A3B3E;
							text-align: center;
							font-size: 12px;
							line-height: 18px;
						}
					}

					.body-content-annual {
						margin-top: 20px;

						.content-annual {
							display: flex;
							justify-content: space-between;
							align-items: center;

							.content-annual-item {
								width: 165px;
								border-radius: 8px;
								background: #FFF;
								padding: 8px 16px;
								box-sizing: border-box;
								margin-bottom: 12px;

								.annual-item-header {
									display: flex;
									align-items: center;

									.annual-item-icon {
										width: 28px;
										height: 28px;
										margin-right: 8px;

										img {
											width: 100%;
											height: 100%;
											object-fit: cover;
										}
									}

									.annual-item-title {
										color: #3A3B3E;
										font-size: 14px;
										line-height: 21px;

										span {
											color: #A1A5B1;
											font-size: 12px;
											line-height: 22px;
										}
									}
								}

								.annual-item-number {
									color: #3A3B3E;
									font-size: 24px;
									line-height: 29px;
									margin-top: 4px;
								}
							}
						}
					}
				}
				
			}
		}
	}
</style>